declare let window: any;

function isDynamicDispatchRoute(router: any, path: string) {
  const match = router.match(path);

  if (match && match.matched && match.matched.length > 0) {
    return false;
  }

  return true;
}

export async function initClientSession(): Promise<void> {
  return await import('./normalize')
    .then((m) => m.normalizeEnvironment())
    .then(async (normalized) => {
      // Grab the configuration form window.__INIT_STATE or fallback on build time configuration
      const dynamicRuntimeConfig = window.__INITIAL_STATE__.config
        ? Object.assign(normalized.config, window.__INITIAL_STATE__.config)
        : normalized.config;

      // Get storeCode from server (received either from cache header or env variable)
      const storeCode = window.__INITIAL_STATE__.user.current_storecode;

      // Initialize the application
      const { app, router, store } = await normalized.createApp(null, dynamicRuntimeConfig, storeCode);

      // Replace state enforcing configuration
      if (window.__INITIAL_STATE__) {
        store.replaceState({
          ...store.state,
          ...window.__INITIAL_STATE__,

          // Prefer using the most uptodate configuration while filling eventually filtered out configuration fields from default
          config:
            normalized.config !== dynamicRuntimeConfig
              ? {
                  ...normalized.config,
                  ...dynamicRuntimeConfig,
                }
              : normalized.config,
        });
      }

      // Ensure dynamic routes registration
      await store.dispatch('url/registerDynamicRoutes');

      // Await for the navigation to finish
      await new Promise((resolve, reject) => router.onReady(resolve, reject));

      // Attempt to mount as early as possible
      normalized.mountIfPossible(app);

      // Register the default navigation guard
      router.beforeResolve(
        normalized.defaultGuardFactory(app, router, store, normalized.config, normalized.currentStoreView),
      );

      // Register offline tasks sync when back online
      normalized.registerSyncTaskProcessor();

      // Register an online event handler allowing to push orders
      window.addEventListener('online', () => {
        normalized.onNetworkStatusChange(store);
      });

      if (isDynamicDispatchRoute(router, router.currentRoute?.path)) {
        const path = router.currentRoute?.path.replace('.html', '');
        const fullPath = router.currentRoute?.fullPath.replace('.html', '');

        router.replace({
          path: `/redirect?to=${path || fullPath}`,
          query: router.currentRoute?.query,
        });
      }
    });
}

export default initClientSession();
